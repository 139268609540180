import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link, StaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import commonLinks from "../constants";

const isDevEnv = process.env.NODE_ENV === "development";

const DevMessage = () => (
  <p>
    Try creating a page in <code>src/pages/</code>.
  </p>
);

const navItems = [
  { link: "/", displayText: "Go home" },
  commonLinks.studio,
  commonLinks.team,
  commonLinks.careers,
  commonLinks.contact,
];

const NotFoundLinks: React.FC = () => (
  <ul className="standard-restricted-width">
    {isDevEnv && <DevMessage />}
    {navItems.map(({ link, displayText }) => (
      <li key={displayText}>
        <Link to={link}>{displayText}</Link>
      </li>
    ))}
  </ul>
);

export const NotFoundPage: React.FC<DefaultPageProps> = ({
  location,
  data,
}) => {
  const pageTitle = "ideas42 Venture Studio - 404 Page";
  return (
    <Layout footerChildren={null}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl + location.pathname}
        />
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <Hero
        title="Page not found"
        titleClass="g-max-3"
        body="Sorry we couldn’t find what you were looking for."
        bodyClass="hero__body--home g-max-3"
      />
      <NotFoundLinks />
    </Layout>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query NotFoundPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <NotFoundPage data={data} {...props} />}
  />
);
